import { Button, Combobox, TextField } from '@schuettflix/react-components';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldError } from '../../Components/FieldError';
import { ImageEdit } from '../../Components/ImageEdit';
import { PhoneInput } from '../../Components/PhoneInput';
import { useBillingAddressSettings } from '../../hooks/api';
import { useSubForm } from '../form';
import { BaseInformationFields } from './common';
export function BaseInformationEdit(props) {
    const { t } = useTranslation();
    const { register, control, formState: { errors }, handleSubmit, watch, } = useSubForm(BaseInformationFields, {
        defaultValues: props.defaultValue,
    });
    const country = watch('billingAddress.country');
    const billingAddressSettings = useBillingAddressSettings();
    return (<form onSubmit={handleSubmit(props.onSave)}>
            <div className="mx-auto flex w-full max-w-screen-md flex-col">
                <p className="font-copy-lg pb-8">{t('pages.organization.form.baseInfo.headline')}</p>
                <TextField {...register('name')} label={t('components.organizationForm.name')}/>
                <FieldError error={errors.name}/>
                <div className=" flex flex-col gap-4 pt-6">
                    <p className="font-copy-md-strong">{t('components.organizationForm.billingAddress.title')}</p>
                    <TextField {...register('billingAddress.additionalAddress')} label={t('components.organizationForm.billingAddress.additionalAddress')}/>
                    <FieldError error={errors.billingAddress?.additionalAddress}/>

                    <div className="flex gap-4">
                        <div className="flex-1">
                            <TextField {...register('billingAddress.street')} label={t('components.organizationForm.billingAddress.street')}/>
                            <FieldError error={errors.billingAddress?.street}/>
                        </div>
                        <div className="w-1/4">
                            <TextField {...register('billingAddress.number')} label={t('components.organizationForm.billingAddress.number')}/>
                            <FieldError error={errors.billingAddress?.number}/>
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <div className="w-1/4">
                            <TextField {...register('billingAddress.zip')} label={t('components.organizationForm.billingAddress.zip')}/>
                            <FieldError error={errors.billingAddress?.zip}/>
                        </div>
                        <div className="flex-1">
                            <TextField {...register('billingAddress.city')} label={t('components.organizationForm.billingAddress.city')}/>
                            <FieldError error={errors.billingAddress?.city}/>
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <div className="flex-1">
                            <Controller control={control} name="billingAddress.country" render={({ field }) => (<Combobox {...field} options={billingAddressSettings.data?.countries.map(c => ({
                value: c.code,
                label: t(c.name),
            })) ?? []} label={t('components.organizationForm.billingAddress.country')}/>)}/>
                            <FieldError error={errors.billingAddress?.country}/>
                        </div>
                        <div className="flex-1">
                            <Controller control={control} name="billingAddress.state" render={({ field }) => (<Combobox {...field} options={billingAddressSettings.data?.countries
                .find(c => c.code === country)
                ?.states.map(s => ({ value: s.code, label: t(s.name) })) ?? []} label={t('components.organizationForm.billingAddress.state')}/>)}/>
                            <FieldError error={errors.billingAddress?.state}/>
                        </div>
                    </div>

                    <p className="font-copy-md-strong">{t('components.organizationForm.billingInfo.title')}</p>
                    <div className="flex gap-4">
                        <div className="flex-1">
                            <TextField {...register('taxId')} label={t('components.organizationForm.tax.taxId')}/>
                            <FieldError error={errors.taxId}/>
                        </div>
                        <div className="flex-1">
                            <TextField {...register('vatId')} label={t('components.organizationForm.tax.vatId')}/>
                            <FieldError error={errors.vatId}/>
                        </div>
                    </div>

                    <p className="font-copy-md-strong">{t('components.organizationForm.contactInfo.title')}</p>
                    <div className="grid grid-cols-2 gap-4">
                        <PhoneInput countryCode={register('phone.countryCode')} number={{
            ...register('phone.number'),
            label: t('components.organizationForm.contact.phone'),
        }}/>
                        <PhoneInput countryCode={register('mobile.countryCode')} number={{
            ...register('mobile.number'),
            label: t('components.organizationForm.contact.mobile'),
        }}/>
                        <PhoneInput countryCode={register('fax.countryCode')} number={{ ...register('fax.number'), label: t('components.organizationForm.contact.fax') }}/>
                    </div>

                    <TextField {...register('email')} label={t('components.organizationForm.contact.email')}/>
                    <FieldError error={errors.email}/>
                    <TextField {...register('web')} label={t('components.organizationForm.contact.website')}/>
                    <FieldError error={errors.web}/>

                    <p className="font-copy-md-strong">{t('components.organizationForm.addLogo.title')}</p>
                    <Controller control={control} name="logo" render={({ field }) => (<ImageEdit value={field.value} onChange={logo => field.onChange(logo)}/>)}/>

                    <div className="flex justify-center gap-4 py-16">
                        <Button type="reset" onClick={props.onCancel} label={t('pages.organization.form.button.cancel.label')} size="sm" variant="secondary"/>
                        <Button type="submit" label={t('pages.organization.form.button.submit.label')} size="sm"/>
                    </div>
                </div>
            </div>
        </form>);
}
