import { allUsersQuery, billingAddressSettingsQuery, organizationByIdQuery, organizationBySalesforceIdQuery, } from '@/reactBridge/queryClient';
import Organization from '@/services/Api/Organization';
import { useMutation, useQuery } from '@tanstack/react-query';
export function useAllEmployees() {
    return useQuery(allUsersQuery);
}
export function useBillingAddressSettings() {
    return useQuery(billingAddressSettingsQuery);
}
export function useOrganizationById(id) {
    return useQuery(organizationByIdQuery(id));
}
export function useOrganizationBySalesForceId(salesforceAccountId) {
    return useQuery(organizationBySalesforceIdQuery(salesforceAccountId));
}
export function useOrganizationSave() {
    return useMutation({
        mutationFn: (org) => Organization.save(org),
    });
}
