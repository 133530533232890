import { Button, MessageBox } from '@schuettflix/react-components';
import { merge } from 'lodash';
import { useCallback, useEffect, useReducer } from 'react';
import { Card } from '../Components/Card';
import { Loader } from '../Components/Loader';
import { useOrganizationSave } from '../hooks/api';
import { AccountingEdit } from './Accounting/Edit';
import { AccountingPreview } from './Accounting/Preview';
import { BaseInformationEdit } from './BaseInformation/Edit';
import { BaseInformationPreview } from './BaseInformation/Preview';
import { SelectTypes } from './components/SelectTypes';
import { useFormOrganization } from './form';
import { useCheckExistingOrg } from './hook';
import { SettingsEdit } from './Settings/Edit';
import { SettingsPreview } from './Settings/Preview';
export function OrganizationForm(props) {
    const form = useFormOrganization({
        defaultValues: props.defaultValues,
    });
    const types = form.watch('types');
    const organizationTypeNeeded = !types || types.length === 0;
    function editReducer(state, action) {
        return { ...state, ...action };
    }
    const [edit, dispatch] = useReducer(editReducer, {
        baseInformation: false,
        accounting: false,
        settings: false,
    });
    const isEditing = Object.values(edit).some(edit => edit === true);
    const orgSave = useOrganizationSave();
    const check = useCheckExistingOrg(props.defaultValues?.id, props.defaultValues?.salesforceAccountId);
    // If a known id is passed, update the form with the current values
    useEffect(() => {
        if (check.org) {
            updateFromChild(check.org);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- it would cause an infinite loop
    }, [check.org]);
    useEffect(() => {
        form.trigger();
        // eslint-disable-next-line react-hooks/exhaustive-deps -- onMount only
    }, []);
    const updateFromChild = useCallback((data) => {
        // Update data and retrigger validation
        form.reset(merge(form.getValues(), data));
        form.trigger();
    }, [form]);
    async function save() {
        if (!form.formState.isValid)
            return;
        orgSave.mutate(form.getValues());
    }
    if (props.onIsValid) {
        props.onIsValid(!isEditing && form.formState.isValid);
    }
    // RENDER
    if (check.isLoading) {
        return <Loader />;
    }
    if (check.idMismatch) {
        return (<div className="flex flex-col gap-2 py-16">
                <Card title="Mismatch">
                    <MessageBox type="error">
                        <p>The organization id and salesforceAccountId do not match. The payload is invalid.</p>
                    </MessageBox>
                </Card>
            </div>);
    }
    if (organizationTypeNeeded) {
        return (<div className="flex flex-col gap-2 py-16">
                <Card title="Select organization type">
                    <SelectTypes onChange={types => form.setValue('types', types)}/>
                </Card>
            </div>);
    }
    return (<div className="flex flex-col gap-2 py-16">
            {/* BASE */}
            {edit.baseInformation ? (<BaseInformationEdit onSave={data => {
                dispatch({ baseInformation: false });
                updateFromChild(data);
            }} onCancel={() => dispatch({ baseInformation: false })} defaultValue={structuredClone(form.getValues())}/>) : (<BaseInformationPreview onEdit={() => dispatch({ baseInformation: true })} form={form}/>)}

            {/* ACCOUNTING */}
            {edit.accounting ? (<AccountingEdit onSave={data => {
                dispatch({ accounting: false });
                updateFromChild(data);
            }} onCancel={() => dispatch({ accounting: false })} defaultValue={structuredClone(form.getValues())}/>) : (<AccountingPreview onEdit={() => dispatch({ accounting: true })} form={form}/>)}

            {edit.settings ? (<SettingsEdit onSave={data => {
                dispatch({ settings: false });
                updateFromChild(data);
            }} onCancel={() => dispatch({ settings: false })} defaultValue={structuredClone(form.getValues())}/>) : (<SettingsPreview onEdit={() => dispatch({ settings: true })} form={form}/>)}

            <Card title="DEV">
                <Button size="sm" label={check.isNew ? 'Create' : 'Update'} onClick={() => save()} disabled={!form.formState.isValid || orgSave.isLoading}/>
            </Card>
        </div>);
}
