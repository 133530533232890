import ApiError from '@/services/Api/ApiError';
import ApiService from '@/services/Api/ApiService';
import { flatten, unflatten } from 'flat';
export async function validate(input) {
    try {
        await ApiService.request({
            method: 'POST',
            url: `v3/organization`,
            data: input,
            params: { validateOnly: true },
        });
        // If 200 there are no errors
        return null;
    }
    catch (e) {
        if (e instanceof ApiError) {
            return e.response?.data?.errors ?? null;
        }
        else {
            throw e;
        }
    }
}
export function mapBackendErrorsToResolver(obj, filterByPath = null) {
    return unflatten(Object.fromEntries(Object.entries(flatten(obj, { safe: true }))
        .filter(([key]) => (filterByPath ? filterByPath.includes(key) : true))
        .map(([key, value]) => {
        if (key === '#') {
            key = 'root';
        }
        return [key, { message: value[0] }];
    })));
}
