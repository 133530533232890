import { useForm } from 'react-hook-form';
import { partialByPath } from '../partial';
import { mapBackendErrorsToResolver, validate } from '../validate';
function organizationFormResolver(fields = null) {
    return async (data) => {
        // There are quite some ts-ignores inside here. I'm sorry, not sure how this can be fixed. The outside APIs are safe though.
        const result = await validate(data);
        if (result === null)
            return { values: data, errors: {} };
        const errors = mapBackendErrorsToResolver(result, fields);
        if (Object.keys(errors).length > 0) {
            return { values: {}, errors };
        }
        else {
            return { values: data, errors: {} };
        }
    };
}
export function useFormOrganization(options) {
    return useForm({
        ...options,
        resolver: organizationFormResolver(),
    });
}
export function useSubForm(fields, options = {}) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return useForm({
        ...options,
        resolver: organizationFormResolver(fields),
    });
}
/**
 * Checks if the errors object contains errors for the given paths.
 */
export function hasErrors(errors, paths) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const relevantErrors = partialByPath(errors, paths.map(field => `${field}.message`));
    return Object.keys(relevantErrors);
}
