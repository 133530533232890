import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';
import { Button, Checkbox, Combobox, RadioButtonCard, TextField } from '@schuettflix/react-components';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CheckBox } from '../../Components/Checkbox';
import { EMailSelector } from '../../Components/EMailSelector';
import { FieldError } from '../../Components/FieldError';
import { useSubForm } from '../form';
import { useIsOrgType } from '../hook';
import { AccountingFields } from './common';
import UnifiedAccounting from './UnifiedAccountingWrapper';
const EMAIL_INTERVAL_FAD = ['directly', 'never'];
const EMAIL_INTERVAL_DELIVERY_NOTES = ['directly', 'daily', 'weekly', 'biweekly', 'never'];
export function AccountingEdit(props) {
    const { t } = useTranslation();
    const { selectCreditNoteDocKey } = useLegalTerms();
    const form = useSubForm(AccountingFields, {
        defaultValues: props.defaultValue,
    });
    const { watch, register, formState: { errors }, control, handleSubmit, } = form;
    const types = watch('types');
    const isType = useIsOrgType(types);
    const isSeller = isType.carrier || isType.supplier;
    return (<form onSubmit={handleSubmit(data => props.onSave(data))}>
            <div className="mx-auto flex w-full max-w-screen-md flex-col gap-8">
                <p className="font-copy-lg">{t('pages.organization.form.accountingInfo.headline')}</p>

                {/* BILLING */}
                <UnifiedAccounting isPlatformUser form={form}/>

                <div>
                    {/* BANK */}
                    <div>
                        <p className="font-copy-md-strong pb-2">{t('components.organizationForm.paymentInfo.title')}</p>
                        <div className="flex flex-col gap-4">
                            <div className="flex gap-4">
                                <div className="flex-1">
                                    <TextField {...register('paymentInfo.iban')} label={t('components.organizationForm.paymentInfo.iban')}/>
                                    <FieldError error={errors.paymentInfo?.iban}/>
                                </div>
                                <div className="w-1/4">
                                    <TextField {...register('paymentInfo.bic')} label={t('components.organizationForm.paymentInfo.bic')}/>
                                    <FieldError error={errors.paymentInfo?.bic}/>
                                </div>
                            </div>
                            <div className="flex gap-4">
                                <div className="flex-1">
                                    <TextField {...register('paymentInfo.name')} label={t('components.organizationForm.paymentInfo.name')}/>
                                    <FieldError error={errors.paymentInfo?.name}/>
                                </div>
                                <div className="w-1/3">
                                    <TextField {...register('paymentInfo.owner')} label={t('components.organizationForm.paymentInfo.owner')}/>
                                    <FieldError error={errors.paymentInfo?.name}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {isSeller && (<div>
                            <p className="font-copy-md-strong mt-2 pb-1">
                                {t('components.organizationForm.paymentInfo.paymentMethod.title')}
                            </p>

                            <div>
                                <Controller control={control} name="paymentInfo.method" render={({ field }) => (<div className="grid grid-cols-2 gap-4">
                                            <RadioButtonCard {...field} inputValue={'invoice'} label={t('components.organizationForm.paymentInfo.paymentMethod.invoice.radioButtonTitle')}/>
                                            <RadioButtonCard {...field} inputValue={'direct_debit'} label={t('components.organizationForm.paymentInfo.paymentMethod.directDebit.radioButtonTitle')}/>
                                        </div>)}/>
                                <FieldError error={errors.paymentInfo?.method}/>
                            </div>

                            <div className="flex gap-4 pt-4">
                                <Checkbox size="sm" className="flex-shrink-0"/>
                                <div>
                                    <p className="font-copy-sm-strong">
                                        {t('components.organizationForm.paymentInfo.mandateCheckbox.title')}
                                    </p>
                                    <p className="font-copy-sm">
                                        {t('components.organizationForm.paymentInfo.mandateCheckbox.text')}
                                    </p>
                                </div>
                            </div>
                        </div>)}
                </div>

                {/* EMAIL */}
                <div>
                    <div className="font-copy-md-strong pb-6">
                        {t('pages.organization.view.headlines.emailPreferences')}
                    </div>
                    <div className="flex flex-col gap-4">
                        <p className="font-copy-md-strong">
                            {t('components.organizationForm.emailPreferences.invoiceIntervalSelectLabel')}
                        </p>

                        <div>
                            <Controller control={control} name="invoiceEmailInterval" render={({ field }) => (<Combobox {...field} label={t('components.organizationForm.emailPreferences.invoiceIntervalSelectLabel')} options={EMAIL_INTERVAL_FAD.map(i => ({
                value: i,
                label: t(`pages.organization.view.value.emailInterval.${i}`),
            }))}/>)}/>
                            <FieldError error={errors.invoiceEmailInterval}/>
                        </div>
                        <Controller control={control} name="invoiceRecipientEmails" render={({ field }) => <EMailSelector {...field}/>}/>

                        <div className="flex gap-4">
                            <div>
                                <Controller control={control} name="emailReceiptAsZip" render={({ field }) => (<CheckBox {...field} label={t('components.organizationForm.emailPreferences.emailReceiptAsZip')}/>)}/>
                                <FieldError error={errors.emailReceiptAsZip}/>
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div>
                                <Controller control={control} name="emailDeliveryNotesAttachedToReceipt" render={({ field }) => (<CheckBox {...field} label={t(selectCreditNoteDocKey({
                creditNote: `components.organizationForm.emailPreferences.emailDeliveryNotesAttachedToReceipt-2.creditNote`,
                selfBilling: `components.organizationForm.emailPreferences.emailDeliveryNotesAttachedToReceipt-2.selfBilling`,
            }))}/>)}/>
                                <FieldError error={errors.emailDeliveryNotesAttachedToReceipt}/>
                            </div>
                        </div>

                        <p className="font-copy-md-strong pt-2">
                            {t('components.organizationForm.emailPreferences.deliveryNote')}
                        </p>
                        <div>
                            <Controller control={control} name="deliveryNoteEmailInterval" render={({ field }) => (<Combobox {...field} label={t('components.organizationForm.emailPreferences.deliveryNoteSelectLabel')} options={EMAIL_INTERVAL_DELIVERY_NOTES.map(i => ({
                value: i,
                label: t(`pages.organization.view.value.emailInterval.${i}`),
            }))}/>)}/>
                            <FieldError error={errors.deliveryNoteEmailInterval}/>
                        </div>

                        <Controller control={control} name="deliveryNoteRecipientEmails" render={({ field }) => <EMailSelector {...field}/>}/>
                        <div>
                            <Controller control={control} name="emailDeliveryNoteAsZip" render={({ field }) => (<CheckBox {...field} label={t('components.organizationForm.emailPreferences.emailDeliveryNoteAsZip')}/>)}/>
                            <FieldError error={errors.emailDeliveryNoteAsZip}/>
                        </div>
                    </div>
                </div>

                <div className="flex justify-center gap-4 py-16">
                    <Button type="reset" onClick={() => props.onCancel()} label={t('pages.organization.form.button.cancel.label')} size="sm" variant="secondary"/>
                    <Button type="submit" label={t('pages.organization.form.button.submit.label')} size="sm"/>
                </div>
            </div>
        </form>);
}
