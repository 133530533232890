import { Button, Textarea } from '@schuettflix/react-components';
import { useState } from 'react';
import { extractDataFromUrl } from '../link';
function encode(raw) {
    try {
        return encodeURI(JSON.stringify(JSON.parse(raw)));
    }
    catch {
        return 'Invalid JSON';
    }
}
const demoLink = {
    organizations: [
        {
            data: {
                name: 'Kah Seng GmbH',
                billingAddress: {
                    country: 'DE',
                    state: 'NW',
                    city: 'Köln',
                    zip: '51105',
                    street: 'Abc str. ',
                    number: '123',
                },
                taxId: '1234512',
                vatId: 'DE123456788',
                web: '',
                email: 'test@schuettflix.com',
                paymentInfo: {
                    iban: '',
                    bic: '',
                    owner: '',
                },
            },
            extra: {
                mobile: '+4917643052308',
                id: {
                    organizationId: '321',
                    salesforceaccountId: '0019V000003Ozv7QAC',
                },
            },
        },
    ],
    users: [
        {
            data: {
                firstName: 'Kah Seng',
                lastName: 'Teh',
                email: 'kahseng.teh@schuettflix.com',
            },
            extra: {
                id: {
                    organizationId: '321',
                    salesforceContactId: '0039V000001BzXTQA0',
                },
                mobile: ' 495625',
            },
        },
        {
            data: {
                firstName: 'Max',
                lastName: 'Schmidt',
                email: 'maximilian.schmidt@schuettflix.com',
            },
            extra: {
                id: {
                    organizationId: '321',
                    salesforceContactId: '0039V0000051yk1QAA',
                },
                mobile: '',
            },
        },
        {
            data: {
                firstName: 'Alexander',
                lastName: 'Nikolai',
                email: 'alexander-declan.nikolai@schuettflix.com',
            },
            extra: {
                id: {
                    organizationId: '321',
                    salesforceContactId: '0039V00000F1abvQAB',
                },
                mobile: '',
            },
        },
    ],
    constructionProjects: [
        {
            data: {
                validFrom: '14. November 2024',
                validTo: '30. November 2024',
                name: '33332 Gütersloh - Test BV - Langer Weg 5',
                description: '',
                costCenter: '',
                bounds: [
                    {
                        latitude: 90,
                        longitude: 180,
                    },
                ],
                responsibleEmployeeId: 0,
                responsibleUserId: 0,
                status: 'INACTIVE',
                country: 'DE',
                state: '',
                city: 'Gütersloh',
                zip: '33332',
                street: 'Langer Weg 5',
                marketCode: 'DE',
            },
            extra: {
                salesforceDealProjectId: 'a1Z9V000002y05dUAA',
                salesforceDealId: '0069V00000I4LwrQAF',
            },
        },
    ],
};
export function UrlCreator() {
    const [value, setValue] = useState(JSON.stringify(extractDataFromUrl() ?? demoLink, null, 2));
    const encoded = encode(value);
    const rows = 20;
    return (<div className="flex flex-col gap-2 p-4">
            <div className="grid grid-cols-2 gap-2">
                <Textarea rows={rows} label="JSON" value={value} onChange={e => setValue(e.target.value)}/>
                <Textarea rows={rows} label="Encoded" readOnly disabled value={encoded}/>
            </div>
            <Button size="sm" label="Save to URL and reload" type="button" onClick={() => {
            window.location.href = `/#/magic?data=${encoded}`;
            window.location.reload();
        }}/>
        </div>);
}
