import { useTranslation } from 'react-i18next';
import { Card } from '../../Components/Card';
import { ErrorOverView } from '../../Components/ErrorOverview';
import { InfoPreview } from '../../Components/InfoPreview';
import { Loader } from '../../Components/Loader';
import { Section } from '../../Components/Section';
import { ToggleSwitchDisplay } from '../../Components/ToggleSwitchDisplay';
import { useAllEmployees } from '../../hooks/api';
import { hasErrors } from '../form';
import { SettingsFields } from './common';
import { useDefinitionsForOrg } from './definition';
export function SettingsPreview(props) {
    const { t } = useTranslation();
    const values = props.form.getValues();
    const errors = hasErrors(props.form.formState.errors, SettingsFields);
    const employees = useAllEmployees();
    const { definitions, sections } = useDefinitionsForOrg(values.types);
    if (employees.isLoading || definitions.isLoading) {
        return <Loader />;
    }
    const salesStaff = employees.data?.find(e => e.id === values.salesStaff?.id) ?? null;
    const accountManager = employees.data?.find(e => e.id === values.accountManager?.id) ?? null;
    return (<Card title={t('pages.organization.form.settings.headline')} onEdit={props.onEdit}>
            <ErrorOverView errors={errors}/>

            <Section>
                <InfoPreview label={t('components.organizationForm.employees.labelSalesStaff')}>
                    {salesStaff && `${salesStaff.firstName} ${salesStaff.lastName}`}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.employees.labelSalesStaff')}>
                    {accountManager && `${accountManager.firstName} ${accountManager.lastName}`}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.keyAccountLabel')}>{values.keyAccount}</InfoPreview>
            </Section>

            <Section title={t('components.organizationForm.constructionProjectSettings.headline')}>
                <InfoPreview label={t(values.costcenterMandatory
            ? 'components.organizationForm.constructionProjectSettings.costcenterMandatory'
            : 'components.organizationForm.constructionProjectSettings.costcenterOptional')}>
                    <ToggleSwitchDisplay checked={values.costcenterMandatory}/>
                </InfoPreview>
            </Section>

            <Section title={t('components.organizationForm.organizationLock.headline')}>
                <InfoPreview label={t(values.isActive
            ? 'components.organizationForm.organizationLock.lockInactive'
            : 'components.organizationForm.organizationLock.lockActive')}>
                    <ToggleSwitchDisplay checked={!values.isActive}/>
                </InfoPreview>
                <InfoPreview label={t(values.isBlocked
            ? 'components.organizationForm.organizationLock.organizationBlocked'
            : 'components.organizationForm.organizationLock.organizationNotBlocked')}>
                    <ToggleSwitchDisplay checked={values.isBlocked}/>
                </InfoPreview>
            </Section>

            <hr className="my-6 border-divider"/>

            {sections
            ?.filter(s => s.isEnabled)
            .map(s => (<Section key={s.key} title={s.title}>
                        <ul className="flex flex-col gap-1">
                            {s.features
                // Only show the ones active
                .filter(f => values.features?.[f.key]?.['active'] === true)
                .map(f => (<li key={f.key}>{f.title}</li>))}
                        </ul>
                    </Section>))}
        </Card>);
}
