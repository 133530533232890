import { MessageBox } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';
export function ErrorOverView(props) {
    const { t } = useTranslation();
    if (!props.errors.length)
        return null;
    return (<MessageBox type="warning" className="mb-6 mt-4">
            {t('pages.organization.form.baseInfo.warning.title')}
            <br />
            {props.errors.map(error => (<span key={error}>{error}</span>))}
        </MessageBox>);
}
