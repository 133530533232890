import { EditIcon } from '@schuettflix/planum-icons-react';
export function Card({ title, children, onEdit }) {
    return (<div className="container flex w-full max-w-screen-md flex-col gap-2 rounded bg-surface p-4 shadow">
            <div className="flex items-center justify-between">
                <div className="font-copy-md">{title}</div>
                {onEdit && (<button type="button" onClick={onEdit}>
                        <EditIcon />
                    </button>)}
            </div>
            <div>{children}</div>
        </div>);
}
