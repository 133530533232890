import { useTranslation } from 'react-i18next';
import { Card } from '../../Components/Card';
import { ErrorOverView } from '../../Components/ErrorOverview';
import { InfoPreview } from '../../Components/InfoPreview';
import { Section } from '../../Components/Section';
import { useBillingAddressSettings } from '../../hooks/api';
import { hasErrors } from '../form';
import { BaseInformationFields } from './common';
export function BaseInformationPreview(props) {
    const { t } = useTranslation();
    const values = props.form.getValues();
    const errors = hasErrors(props.form.formState.errors, BaseInformationFields);
    const billingAddressSettings = useBillingAddressSettings();
    const country = billingAddressSettings.data?.countries.find(c => c.code === values.billingAddress?.country) ?? null;
    const state = country?.states.find(s => s.code === values.billingAddress?.state) ?? null;
    function formatPhone(number) {
        return `${number.countryCode} ${number.number}`;
    }
    return (<Card title={t('pages.organization.form.baseInfo.headline')} onEdit={props.onEdit}>
            <ErrorOverView errors={errors}/>
            <div className="grid grid-cols-2 gap-2">
                <InfoPreview label={t('components.organizationForm.name')}>{values.name}</InfoPreview>
                <InfoPreview label={t('components.organizationForm.billingAddress.additionalAddress')} className="col-start-1">
                    {values.additionalInformation}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.billingAddress.streetNumber')} className="col-start-1">
                    {values.billingAddress?.street} {values.billingAddress?.number}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.billingAddress.cityZip')} className="col-start-1">
                    {values.billingAddress && `${values.billingAddress.zip} ${values.billingAddress.city}`}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.billingAddress.country')} className="col-start-1">
                    {country && t(country.name)}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.billingAddress.state')} className="col-start-1">
                    {state && t(state.name)}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.tax.taxId')} className="col-start-2 row-start-1">
                    {values.taxId}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.tax.vatId')} className="col-start-2 row-start-2">
                    {values.vatId}
                </InfoPreview>
            </div>

            <hr className="my-6 border-divider"/>
            <Section title={t('components.organizationForm.contact.title')}>
                <InfoPreview label={t('components.organizationForm.contact.phone')}>
                    {values.phone && formatPhone(values.phone)}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.contact.mobile')}>
                    {values.mobile && formatPhone(values.mobile)}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.contact.fax')}>
                    {values.fax && formatPhone(values.fax)}
                </InfoPreview>

                <InfoPreview label={t('components.organizationForm.contact.email')} className="col-start-1">
                    {values.email}
                </InfoPreview>
                <InfoPreview label={t('components.organizationForm.contact.website')}>{values.web}</InfoPreview>
            </Section>
            <hr className="my-6 border-divider"/>
            <Section title={t('components.organizationForm.logo.title')}>
                {values.logo && 'url' in values.logo && (<img className="h-icon-size-lg w-auto overflow-hidden rounded object-center" src={values.logo.url.small} alt={values.logo.fileName}/>)}
            </Section>
        </Card>);
}
