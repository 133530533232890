import { MutationCache, QueryCache } from '@tanstack/query-core';
import { QueryClient } from '@tanstack/react-query';
import Ability from '@/services/Api/Ability';
import Authenticator from '@/services/Api/Authenticator';
import ProjectApi from '@/services/Api/Project';
import PlatformApi from '@/services/Api/Platform/Utils';
import NetsuiteApi from '@/services/Api/NetSuite';
import OrganizationApi from '@/services/Api/Organization';
import UserApi from '@/services/Api/Platform/User';
import { billingAddressSettingsApi } from '@/services/Api/Platform/BillingAddressSettings';
// Mutation and Query caches are shared between Vue and React
export const mutationCache = new MutationCache();
export const queryCache = new QueryCache();
// This query client is only used for React components
export const queryClient = new QueryClient({
    mutationCache,
    queryCache,
});
export const platformInfoQuery = {
    queryKey: ['platform', 'info'],
    queryFn: () => PlatformApi.getPlatformInfo(),
    cacheTime: Infinity,
};
export const abilitiesQuery = {
    queryKey: ['abilities'],
    queryFn: () => Ability.getAll(),
};
export const userInfoQuery = {
    queryKey: ['user', 'info'],
    queryFn: () => Authenticator.requestUserInfo(),
};
export const projectsKeys = {
    namespace: ['projects'],
    byId: (id) => [...projectsKeys.namespace, id],
};
export const netsuitePaymentTermsQuery = {
    queryKey: ['organization', 'paymentTerms'],
    queryFn: () => NetsuiteApi.getNetSuitePaymentTerms(),
};
export const allUsersQuery = {
    queryKey: ['user', 'all'],
    queryFn: () => UserApi.getAll(),
};
export const billingAddressSettingsQuery = {
    queryKey: ['billingAddress', 'settings'],
    queryFn: () => billingAddressSettingsApi.getBillingAddressSettings(),
};
export function organizationByIdQuery(id) {
    return {
        queryKey: ['organization', 'byId', id],
        queryFn: async () => {
            if (id === undefined)
                return null;
            const org = await OrganizationApi.getOneById(id);
            return org;
        },
    };
}
export function organizationBySalesforceIdQuery(salesforceAccountId) {
    const filter = { salesforceAccountId };
    return {
        queryKey: ['organization', 'filter', filter],
        queryFn: async () => {
            if (!filter.salesforceAccountId)
                return null;
            const response = await OrganizationApi.filter(filter);
            const org = response.items.length ? response.items[0] : null;
            return org;
        },
    };
}
export function projectByIdQuery(id) {
    return {
        queryKey: projectsKeys.byId(id),
        queryFn: () => ProjectApi.getOneById(id),
    };
}
export async function prefetchQueries() {
    await Promise.allSettled([
        queryClient.prefetchQuery(abilitiesQuery),
        queryClient.prefetchQuery(userInfoQuery),
        queryClient.prefetchQuery(platformInfoQuery),
    ]);
}
