import { Tag, TextField } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';
export function EMailSelector(props) {
    const { t } = useTranslation();
    const emails = props.value ?? [];
    return (<div className="flex flex-col gap-1">
            <TextField type="email" label={t('components.organizationForm.emailPreferences.email.label')} onKeyDown={e => {
            if (e.key === 'Enter' && e.target instanceof HTMLInputElement) {
                e.preventDefault();
                e.stopPropagation();
                const valid = e.target.checkValidity();
                const value = e.target.value;
                if (valid && !emails.includes(value)) {
                    props.onChange([...emails, value]);
                    e.target.value = '';
                }
            }
        }}/>
            <div className="flex items-start gap-1">
                {emails.map(email => (<Tag key={email} type="default" deletable={emails.length > 1} label={email} onDelete={() => {
                const filtered = emails.filter(e => e !== email);
                props.onChange(filtered.length ? filtered : null);
            }}/>))}
            </div>
        </div>);
}
