import { Magic } from './Magic';
export default [
    {
        path: 'magic',
        name: 'magic',
        component: Magic,
        meta: {
            requiresAuth: true,
            noBackArrow: true,
        },
    },
];
