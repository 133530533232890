import React, { Suspense, useState, useEffect } from 'react';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { useTranslation } from 'react-i18next';
import { ProjectOrderIcon } from '../components/icons/ProjectOrderIcon';
import { DeliveryOrderIcon } from '../components/icons/DeliveryOrderIcon';
import { ForwardingOrderIcon } from '../components/icons/ForwardingOrderIcon';
import { PickupOrderIcon } from '../components/icons/PickupOrderIcon';
import { ChevronRightIcon } from '../components/icons/ChevronRightIcon';
import { FlyoutPage } from '@/constructionProjects/components/FlyoutPage';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { useAbility } from '@/reactBridge/useAbility';
import { useVuexState } from '@/reactBridge/useVuex';
import store from '@/store';
import { Header } from '@/constructionProjects/components/Header';
import { CONSTRUCTION_PROJECT_CLIENT_SETTINGS_ROUTE, CONSTRUCTION_PROJECT_DETAILS_ROUTE, CONSTRUCTION_PROJECT_ORDER_ROUTE, } from '../constants';
import { useQuery } from '@tanstack/react-query';
import { constructionProjectByIdQuery } from '../queries';
import { organizationByIdQuery, userInfoQuery } from '@/reactBridge/queryClient';
import { useScreenName } from '@schuettflix/analytics-react';
import { MessageBox } from '@schuettflix/react-components';
import { format } from 'date-fns';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
import { useLaunchDarkly } from '@/reactBridge/useLaunchDarkly';
import ProjectApi from '@/services/Api/Project';
const jotformId = 231073376312348;
const OrderCard = ({ dataTest, label, icon, onClick }) => {
    return (<div className="card mb-4 flex cursor-pointer items-center rounded" data-test={dataTest} onClick={onClick}>
            {icon}
            <p className="font-copy-sm-strong ml-2">{label}</p>
            <ChevronRightIcon className="ml-auto"/>
        </div>);
};
const ClientOrderButtonGroup = ({ hasCostCenter }) => {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    const canSubmitProjectOrder = useAbility('createProjectPositionQuote');
    const canCreateDeliveryQuote = useAbility('createDeliveryQuote');
    const canCreateShipmentQuote = useAbility('createShipmentQuote');
    const canCreatePickupQuote = useAbility('createPickupQuote');
    const canManageConstructionProjects = useAbility('haveConstructionProjectManagement');
    const costCenterMandatory = useVuexState(store, state => state.user.info.organization.costcenterMandatory);
    const constructionProjectId = useVuexState(store, state => state.constructionProject.constructionProjectId);
    const showInfoBox = !canManageConstructionProjects && costCenterMandatory && hasCostCenter;
    const showWarningBox = canManageConstructionProjects && costCenterMandatory && hasCostCenter;
    const navigateToSettings = async () => {
        store.commit('constructionProject/setEntryPoint', {
            name: CONSTRUCTION_PROJECT_ORDER_ROUTE,
        });
        await vueRouter.push({
            name: `${CONSTRUCTION_PROJECT_DETAILS_ROUTE}__${CONSTRUCTION_PROJECT_CLIENT_SETTINGS_ROUTE}`,
            params: { id: constructionProjectId },
        });
    };
    return (<>
            {showInfoBox && (<MessageBox className="mb-4">
                    <p className="text-on-info-subdued font-copy-md">
                        {t('pages.constructionProject.orderPage.order.costcenter.info')}
                    </p>
                </MessageBox>)}

            {showWarningBox && (<MessageBox className="mb-4" type="warning">
                    <p className="font-copy-md">
                        {t('pages.constructionProject.orderPage.order.costcenter.warning.text')}
                    </p>
                    <div className="mt-4 flex">
                        <button onClick={navigateToSettings}>
                            <span className="font-copy-md-strong">
                                {t('pages.constructionProject.orderPage.order.costcenter.warning.link')} ›
                            </span>
                        </button>
                    </div>
                </MessageBox>)}

            {canSubmitProjectOrder && (<OrderCard dataTest="project-order-card" label={t('pages.constructionProject.orderPage.order.project.title')} icon={<ProjectOrderIcon />} onClick={() => vueRouter.push({
                name: 'order__checkout__checkout-main__project-position__checkout-project-position',
            })}/>)}
            {canCreateDeliveryQuote && (<OrderCard dataTest="delivery-card" label={t('pages.constructionProject.orderPage.order.delivery.title')} icon={<DeliveryOrderIcon />} onClick={() => {
                store.dispatch('basket/init', { type: 'delivery', isCustom: false });
                store.commit('projectPosition/RESET');
                vueRouter.push({
                    name: 'order__checkout__checkout-main__delivery__checkout-delivery',
                });
            }}/>)}
            {canCreateShipmentQuote && (<OrderCard dataTest="shipment-order-card" label={t('pages.constructionProject.orderPage.order.forwarding.title')} icon={<ForwardingOrderIcon />} onClick={() => {
                store.dispatch('basket/init', { type: 'shipment', isCustom: false });
                store.commit('projectPosition/RESET');
                vueRouter.push({ name: 'order__checkout__checkout-main__shipment__checkout-shipment' });
            }}/>)}
            {canCreatePickupQuote && (<OrderCard dataTest="pickup-card" label={t('pages.constructionProject.orderPage.order.pickup.title')} icon={<PickupOrderIcon />} onClick={() => {
                store.dispatch('basket/init', { type: 'pickup', isCustom: false });
                store.commit('projectPosition/RESET');
                vueRouter.push({ name: 'order__checkout__checkout-main__pickup__checkout-pickup' });
            }}/>)}
        </>);
};
const CustomOrderButtonGroup = () => {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    return (<>
            <OrderCard dataTest="custom-delivery-card" label={t('pages.constructionProject.orderPage.order.delivery.title')} icon={<DeliveryOrderIcon />} onClick={() => {
            store.dispatch('basket/init', { type: 'delivery', isCustom: true });
            store.commit('projectPosition/RESET');
            vueRouter.push({
                name: 'order__checkout__checkout-main__custom-delivery__checkout-custom-delivery',
            });
        }}/>
            <OrderCard dataTest="custom-shipment-card" label={t('pages.constructionProject.orderPage.order.forwarding.title')} icon={<ForwardingOrderIcon />} onClick={() => {
            store.dispatch('basket/init', { type: 'shipment', isCustom: true });
            store.commit('projectPosition/RESET');
            vueRouter.push({
                name: 'order__checkout__checkout-main__custom-shipment__checkout-custom-shipment',
            });
        }}/>
        </>);
};
const JotFormEmbed = ({ formId, formParams, trackSend }) => {
    const language = useVuexState(store, state => state.i18n.locale);
    const [loadCounter, setLoadCounter] = useState(0);
    // NOTE: We are only interested in the second onload event, which should be the form submission.
    const handleIframOnload = () => {
        if (loadCounter === 1) {
            trackSend();
        }
        setLoadCounter(loadCounter + 1);
    };
    const mapJotFormUrl = () => {
        const url = new URL(`https://form.jotform.com/${formId}`);
        url.searchParams.append('language', language.slice(0, 2));
        if (formParams) {
            Object.entries(formParams).forEach(([key, value]) => {
                if (value && value !== '') {
                    url.searchParams.append(key, value);
                }
            });
        }
        return url.toString();
    };
    const formUrl = mapJotFormUrl();
    return <iframe src={formUrl} className="h-full w-full" onLoad={handleIframOnload}/>;
};
const ConstructionProjectOrder = () => {
    useScreenName('client-orderprocess-entry');
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    const canCreateCustomQuote = useAbility('createCustomQuote');
    const entryPoint = useVuexState(store, state => state.constructionProject.entryPoint);
    const constructionProjectId = useVuexState(store, state => state.constructionProject.constructionProjectId);
    const customRequestJotformEnabled = useLaunchDarkly('custom-request-jotform');
    const [projectId, setProjectId] = useState(undefined);
    const [flyoutOpen, setFlyoutOpen] = useState(false);
    useEffect(() => {
        const getProjectId = async () => {
            const data = await ProjectApi.projectsByConstructionProjectId(constructionProjectId);
            const projectId = data.items[0].id;
            setProjectId(projectId);
        };
        getProjectId();
    }, [constructionProjectId]);
    const constructionProject = useQuery({
        ...constructionProjectByIdQuery(constructionProjectId),
        suspense: true,
        // disable call for platform admins as it is currently resulting in 403 anyway
        enabled: !canCreateCustomQuote,
    });
    const { data: organization } = useQuery({
        ...organizationByIdQuery(constructionProject.data?.organizationId),
        suspense: true,
        enabled: !!constructionProject.data?.organizationId,
    });
    const { data: user } = useQuery({
        ...userInfoQuery,
        suspense: true,
    });
    const handleCustomOrder = () => {
        AnalyticsService.trackEvent('jotform', {
            step: 'open',
            constructionProjectId: constructionProject.data?.id,
            formId: jotformId,
        });
        setFlyoutOpen(true);
    };
    const handleTrackSend = () => {
        AnalyticsService.trackEvent('jotform', {
            step: 'send',
            constructionProjectId: constructionProject.data?.id,
            formId: jotformId,
        });
    };
    const handleBackButton = async () => {
        await vueRouter.push({ name: entryPoint?.name ?? 'home', params: entryPoint?.params });
    };
    const hasEntrypoint = entryPoint?.name && [CONSTRUCTION_PROJECT_DETAILS_ROUTE, 'home'].includes(entryPoint.name);
    const navigationTitle = hasEntrypoint && entryPoint.name === CONSTRUCTION_PROJECT_DETAILS_ROUTE
        ? constructionProject.data?.name
        : t('pages.homeV2.title');
    return (<div className="flex h-full flex-col">
            <Header desktopTitle={t('pages.constructionProject.orderPage.header.title')} mobileTitle={t('pages.constructionProject.orderPage.header.title')} mobileDescription={constructionProject.data?.name} backgroundColor={constructionProject.data?.color} navigationTitle={navigationTitle} onBack={hasEntrypoint ? handleBackButton : undefined}/>

            <div className="overflow-auto p-4 pt-6">
                {canCreateCustomQuote ? (<CustomOrderButtonGroup />) : (<ClientOrderButtonGroup hasCostCenter={!constructionProject.data?.costCenter}/>)}

                {customRequestJotformEnabled && (<div className="mt-12">
                        <div>
                            <p className="font-copy-lg-strong pb-4">
                                {t('pages.constructionProject.orderPage.customOrder.header')}
                            </p>
                            <p className="font-copy-md pb-4">
                                {t('pages.constructionProject.orderPage.customOrder.description')}
                            </p>
                            <button onClick={handleCustomOrder}>
                                <p className="font-copy-md-strong">
                                    {t('pages.constructionProject.orderPage.customOrder.submit')} ›
                                </p>
                            </button>
                        </div>
                    </div>)}
            </div>

            {flyoutOpen && (<FlyoutPage screenName="client-orderprocess-customorder" onCloseAnimationEnd={() => setFlyoutOpen(false)} headerTitle={t('pages.constructionProject.orderPage.customOrder.flyout.header')} body={<JotFormEmbed formId={jotformId} formParams={constructionProject.data && {
                    // NOTE: Jotform hidden fields should be mapped to the input's unique name
                    // https://www.jotform.com/help/146-how-to-find-field-ids-and-names/
                    orgName: `${organization?.name}`,
                    orgId: `${organization?.id}`,
                    projectId: `${projectId}`,
                    projectName: `${constructionProject.data?.name}`,
                    country: `${constructionProject.data?.country}`,
                    city: `${constructionProject.data?.city}`,
                    zipCode: `${constructionProject.data?.zip}`,
                    streetAddress: `${constructionProject.data?.street} ${constructionProject.data?.addressNumber}`,
                    // @ts-expect-error: Authenticator API is not type-safe
                    fullName: `${user.user?.firstName} ${user.user?.lastName}`,
                    // @ts-expect-error: Authenticator API is not type-safe
                    userId: `${user.user?.id}`,
                    'startDate[day]': `${format(constructionProject.data?.validFrom, 'DD')}`,
                    'startDate[month]': `${format(constructionProject.data?.validFrom, 'MM')}`,
                    'startDate[year]': `${format(constructionProject.data?.validFrom, 'YYYY')}`,
                    'endDate[day]': `${format(constructionProject.data?.validTo, 'DD')}`,
                    'endDate[month]': `${format(constructionProject.data?.validTo, 'MM')}`,
                    'endDate[year]': `${format(constructionProject.data?.validTo, 'YYYY')}`,
                }} trackSend={handleTrackSend}/>}/>)}
        </div>);
};
export const ConstructionProjectOrderPage = asVueComponent(() => {
    const constructionProjectId = useVuexState(store, state => state.constructionProject.constructionProjectId);
    if (!constructionProjectId) {
        return null;
    }
    return (<Suspense>
            <ConstructionProjectOrder />
        </Suspense>);
});
