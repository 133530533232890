import { asVueComponent } from '@/reactBridge/asVueComponent';
import { CloseIcon } from '@schuettflix/planum-icons-react';
import { Button } from '@schuettflix/planum-react';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { UrlCreator } from './Components/UrlCreator';
import { OrganizationForm } from './Organization/OrganizationForm';
import { extractDataFromUrl } from './link';
export const Magic = asVueComponent(() => {
    const [scrollPosition, setScrollPosition] = useState('top');
    const magicData = extractDataFromUrl();
    const [currentStep, setCurrentStep] = useState(0);
    const [isValid, setIsValid] = useState(false);
    if (!magicData) {
        return (<div>
                <p>Link Invalid</p>
                <UrlCreator />
            </div>);
    }
    const totalSteps = magicData.organizations.length + magicData.users.length + magicData.constructionProjects.length;
    const getStep = useCallback((i) => {
        if (!magicData || i < 0)
            throw new Error('magicData is undefined');
        if (i < magicData.organizations.length) {
            const data = magicData.organizations[i];
            return <OrganizationForm defaultValues={data.data} onIsValid={setIsValid}/>;
        }
        if (i < magicData.organizations.length + magicData.users.length) {
            // const data = magicData.users[i - magicData.organizations.length]
            throw new Error('Not implemented');
        }
        // const data= magicData.constructionProjects[i - magicData.organizations.length - magicData.users.length]
        throw new Error('Not implemented');
    }, [magicData]);
    /**
     * Progress to next step
     */
    function next() {
        if (!isValid)
            return;
        // TODO: Save / create entity
        setIsValid(false);
        setCurrentStep(currentStep + 1);
    }
    return (<div className="fixed inset-0 z-10 flex max-h-full flex-col overflow-auto bg-light-gray-200">
            <nav className={clsx('flex justify-between gap-4 bg-surface px-6 py-4', scrollPosition !== 'top' && 'shadow-[0px_-5px_50px_-20px_#000000c7]')}>
                <div className="flex flex-col gap-2">
                    <h1 className="font-headline-lg-strong">Daten übertragen</h1>
                    <div className="font-copy-sm">
                        Schritt {currentStep + 1} von {totalSteps}: Organisation erstellen
                    </div>
                </div>
                <a href="/#/">
                    <Button type="button" prominence="secondary" trailingSlot={<CloseIcon />}>
                        Beenden
                    </Button>
                </a>
            </nav>
            <div className="flex-1 overflow-y-auto" style={{ scrollbarGutter: 'stable both-edges' }} onScroll={e => {
            if (e.target instanceof HTMLDivElement) {
                const s = e.target.scrollTop / (e.target.scrollHeight - e.target.clientHeight);
                setScrollPosition(s === 0 ? 'top' : s === 1 ? 'bottom' : 'middle');
            }
        }}>
                {/* CONTENT */}
                {currentStep === -1 ? (<div className="m-auto max-w-screen-md py-8">
                        <h2 className="font-headline-md-strong">You want to create {totalSteps} entities</h2>
                        <ul>
                            <li>{magicData.organizations.length} Organizations</li>
                            <li>{magicData.users.length} Users</li>
                            <li>{magicData.constructionProjects.length} Construction Projects</li>
                        </ul>
                    </div>) : (getStep(currentStep))}
                <UrlCreator />
            </div>
            <footer className={clsx('bg-light-gray-200', scrollPosition !== 'bottom' && 'shadow-[0px_-5px_50px_-20px_#000000c7]')}>
                <div className="mx-auto flex max-w-screen-md justify-end gap-2 py-2">
                    <Button type="button" onPress={next} isDisabled={!isValid}>
                        Weiter
                    </Button>
                </div>
            </footer>
        </div>);
});
