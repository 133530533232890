import { PRIVILEGED_INTERVALS } from '@/constants/billingInterval';
import { PaymentTermsSelection } from '@/features/PaymentTermsSelection';
import { useAbility } from '@/reactBridge/useAbility';
import { Checkbox, ComboBox } from '@schuettflix/planum-react';
import { MessageBox } from '@schuettflix/react-components';
import { useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useIsOrgType } from '../hook';
import { useBillingIntervals } from './hooks';
export function IntervalSelector(props) {
    const { t } = useTranslation();
    const [currentValue] = useState(props.value);
    const allIntervals = useBillingIntervals();
    const intervals = allIntervals.intervals[props.type];
    const showWarning = currentValue &&
        props.value &&
        PRIVILEGED_INTERVALS.includes(currentValue.interval) &&
        !PRIVILEGED_INTERVALS.includes(props.value.interval);
    if (allIntervals.isLoading) {
        return (<div className="animate-pulse" role="status">
                <div className="h-[4.5rem] rounded bg-surface-hovered"/>
            </div>);
    }
    return (<div className="flex flex-col gap-2">
            <ComboBox menuTrigger="focus" label={props.label} isDisabled={props.isDisabled && !props.canDowngrade} selectedKey={intervals.find(i => i.interval.group === props.value?.group && i.interval.interval === props.value?.interval)?.id ?? null} onSelectionChange={key => {
            const interval = intervals.find(i => i.id === key);
            if (interval && props.onChange) {
                props.onChange(interval.interval);
            }
        }}>
                {intervals.map(interval => {
            const label = t(`pages.organization.form.interval.${interval.id}`);
            return (<ComboBox.Option isDisabled={props.canDowngrade && !interval.canBeDowngradedTo} key={interval.id} id={interval.id} label={label} textValue={label}/>);
        })}
            </ComboBox>
            {showWarning && (<MessageBox type="warning">
                    <p>
                        You selected a shorter interval than guaranteed by Schüttflix, you cannot come back to this
                        interval on your own after saving.
                    </p>
                </MessageBox>)}
        </div>);
}
function guard({ group, interval }) {
    if (group && interval)
        return { group, interval };
    return undefined;
}
export default function UnifiedAccounting(props) {
    const { t } = useTranslation();
    const orgId = props.form.watch('id');
    const types = props.form.watch('types');
    const isPartnerInvoicingEnabled = props.form.watch('isPartnerInvoicingEnabled');
    const invoice = {
        group: props.form.watch('invoiceBillingGroup'),
        interval: props.form.watch('invoiceBillingInterval'),
    };
    const supplier = {
        group: props.form.watch('creditNoteSupplierBillingGroup'),
        interval: props.form.watch('creditNoteSupplierBillingInterval'),
    };
    const carrier = {
        group: props.form.watch('creditNoteCarrierBillingGroup'),
        interval: props.form.watch('creditNoteCarrierBillingInterval'),
    };
    const { carrier: isCarrier, client: isClient, supplier: isSupplier } = useIsOrgType(types);
    const abilities = {
        organizationAccountingSettingManagement: useAbility('organizationAccountingSettingManagement'),
        updateOrganizationExcludedFromDunning: useAbility('updateOrganizationExcludedFromDunning'),
        updateOrganizationPlatformSettings: useAbility('updateOrganizationPlatformSettings'),
        updateOrganization: useAbility('updateOrganization', { id: orgId }),
    };
    const canEditAccounting = abilities.organizationAccountingSettingManagement;
    const canDowngrade = !props.isPlatformUser && abilities.updateOrganization;
    // PaymentTerms Component
    const PaymentTerms = useCallback(({ type }) => {
        const selector = `${type}PaymentTerm`;
        const value = props.form.watch(selector);
        return (<PaymentTermsSelection disabled={!canEditAccounting} showLabel={false} organizationType={type} paymentTermId={value?.id ?? null} onChange={id => {
                if (id === null)
                    return;
                props.form.setValue(`${selector}`, { id: id });
            }}/>);
    }, [props.form, canEditAccounting]);
    return (<div className="flex flex-col gap-4">
            <h2 className="font-headline-md-strong">{t('pages.organization.accounting.headline')}</h2>

            <div className="flex flex-col gap-12">
                {isClient && (<div className="flex flex-col gap-4">
                        <h3 className="font-copy-md-strong">{t('pages.organization.accounting.client.title')}</h3>
                        {props.isPlatformUser && (<Controller control={props.form.control} name="excludedFromDunning" render={({ field: { value, ...field } }) => (<Checkbox {...field} isDisabled={!abilities.updateOrganizationExcludedFromDunning} label={t('pages.oragization.accounting.form.checkbox.noDunning')} isSelected={value}/>)}/>)}
                        <div className="grid grid-cols-2 gap-4">
                            <PaymentTerms type="client"/>
                            <IntervalSelector isDisabled={!canEditAccounting} canDowngrade={canDowngrade} type="invoice" label={t('pages.organization.accounting.form.interval.label')} value={guard(invoice)} onChange={interval => {
                props.form.setValue('invoiceBillingInterval', interval.interval);
                props.form.setValue('invoiceBillingGroup', interval.group);
            }}/>
                        </div>
                    </div>)}

                {(isSupplier || isCarrier) && (<div className="flex flex-col gap-4">
                        <h3 className="font-copy-md-strong">{t('pages.organization.accounting.supplier.title')}</h3>
                        <Controller control={props.form.control} name="isPartnerInvoicingEnabled" render={({ field: { value, ...rest } }) => (<Checkbox {...rest} isDisabled={!canEditAccounting} label={t('pages.organization.form.partnerInvoicingSwitch')} isSelected={value}/>)}/>
                        {isSupplier && (<div className="flex flex-col gap-2">
                                <div className="font-copy-md">
                                    {t('pages.organization.accounting.form.supplier.title')}
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                    <PaymentTerms type="supplier"/>
                                    <IntervalSelector label={t('pages.organization.accounting.form.interval.label')} isDisabled={isPartnerInvoicingEnabled || !canEditAccounting} canDowngrade={canDowngrade} type="creditNote" value={guard(supplier)} onChange={interval => {
                    props.form.setValue('creditNoteSupplierBillingInterval', interval.interval);
                    props.form.setValue('creditNoteSupplierBillingGroup', interval.group);
                }}/>
                                </div>
                            </div>)}
                        {isCarrier && (<div className="flex flex-col gap-2">
                                <div className="font-copy-sm-strong">
                                    {t('pages.organization.accounting.form.carrier.title')}
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                    <PaymentTerms type="carrier"/>
                                    <IntervalSelector label={t('pages.organization.accounting.form.interval.label')} isDisabled={isPartnerInvoicingEnabled || !canEditAccounting} canDowngrade={canDowngrade} type="creditNote" value={guard(carrier)} onChange={interval => {
                    props.form.setValue('creditNoteCarrierBillingInterval', interval.interval);
                    props.form.setValue('creditNoteCarrierBillingGroup', interval.group);
                }}/>
                                </div>
                            </div>)}
                    </div>)}
            </div>
        </div>);
}
