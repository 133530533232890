import { parsePhoneNumber } from 'awesome-phonenumber';
import { z } from 'zod';
const phoneSchema = z.string().transform(value => {
    const parsed = parsePhoneNumber(value, { regionCode: 'DE' });
    if (parsed.valid) {
        return { countryCode: `+${parsed.countryCode}`, number: parsed.number.national };
    }
    return null;
});
const magicLinkDataSchema = z.object({
    organizations: z.array(z
        .object({
        data: z
            .object({
        // name: z.string(),
        // billingAddress: z.object({
        //   country: z.string(),
        //   state: z.string(),
        //   city: z.string(),
        //   zip: z.string()
        // }),
        // taxId: z.string(),
        // vatId: z.string(),
        // web: z.string(),
        // email: z.string(),
        // paymentInfo: z.object({
        //   iban: z.string(),
        //   bic: z.string(),
        //   owner: z.string()
        // })
        })
            .passthrough(),
        extra: z
            .object({
            id: z.object({
                organizationId: z.string(),
                salesforceaccountId: z.string(),
            }),
            phone: phoneSchema.optional(),
            mobile: phoneSchema.optional(),
            fax: phoneSchema.optional(),
        })
            .optional(),
    })
        .transform(org => {
        if (org.extra) {
            if (org.extra.mobile) {
                org.data.mobile = org.extra.mobile;
            }
            if (org.extra.phone) {
                org.data.phone = org.extra.phone;
            }
            if (org.extra.fax) {
                org.data.fax = org.extra.fax;
            }
        }
        return org;
    })),
    users: z.array(z.object({
        data: z
            .object({
        // firstName: z.string(),
        // lastName: z.string(),
        // email: z.string()
        })
            .passthrough(),
        extra: z.object({
            id: z.object({
                organizationId: z.string(),
                salesforceContactId: z.string(),
            }),
            mobile: z.string(),
        }),
    })),
    constructionProjects: z.array(z.object({
        data: z
            .object({
        // validFrom: z.string(),
        // validTo: z.string(),
        // name: z.string(),
        // description: z.string(),
        // costCenter: z.string(),
        // bounds: z.array(
        //   z.object({ latitude: z.number(), longitude: z.number() })
        // ),
        // responsibleEmployeeId: z.number(),
        // responsibleUserId: z.number(),
        // status: z.string(),
        // country: z.string(),
        // state: z.string(),
        // city: z.string(),
        // zip: z.string(),
        // street: z.string(),
        // marketCode: z.string()
        })
            .passthrough(),
        extra: z.object({
            salesforceDealProjectId: z.string(),
            salesforceDealId: z.string(),
        }),
    })),
});
export function extractDataFromUrl() {
    const searchParams = new URLSearchParams(window.location.hash.slice(window.location.hash.indexOf('?') + 1));
    const dataAsString = searchParams.get('data');
    if (!dataAsString)
        return null;
    try {
        const data = JSON.parse(dataAsString);
        const parsed = magicLinkDataSchema.parse(data);
        return parsed;
    }
    catch (e) {
        console.error(e);
        return null;
    }
}
